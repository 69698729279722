<script setup lang="ts">
import { ref } from '#imports'
import Button from './Button.vue'

defineProps<{
  viewMoreFeature?: boolean
  title?: string
}>()

const showFullHeight = ref(false)
const toggleViewMore = () => {
  showFullHeight.value = !showFullHeight.value
}
</script>

<template>
  <div class="rounded-lg bg-neutral-50 px-2 py-5">
    <p v-if="title" class="subtitle-2 text-primary-900 mb-2 px-2">
      {{ title }}
    </p>
    <div
      class="body-2 mb-1 px-2 text-neutral-900"
      :class="{ 'line-clamp-5': viewMoreFeature && !showFullHeight }"
    >
      <slot />
    </div>
    <Button
      v-if="viewMoreFeature && !showFullHeight"
      size="xs"
      variant="text"
      @click="toggleViewMore"
    >
      View more
    </Button>
    <Button
      v-if="viewMoreFeature && showFullHeight"
      size="xs"
      variant="text"
      @click="toggleViewMore"
    >
      View less
    </Button>
  </div>
</template>
