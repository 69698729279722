<script setup lang="ts">
import type { Api } from '@rialtic/api'
import type { AuthHeaders } from '@rialtic/types'

const props = defineProps<{
  policyId: string
  startDate: string
}>()

const { $auth0 } = useNuxtApp()
const { getAccessTokenSilently } = $auth0()

const areDocsViewable = ref(false)
const activeSourceDoc = ref<Api.SourceDocument | null>(null)
const activeSourceDocFullscreen = ref(false)
const reqHeaders = ref<AuthHeaders | null>(null)

const query = computed(() => ({
  start_date: props.startDate,
}))

const { data: policyDetails, pending } = useApiFetch(
  () => `/api/policy/${props.policyId}/details`,
  {
    lazy: true,
    query,
  },
)

const onDownloadFile = async (sourceDoc: Api.SourceDocument) => {
  const blob = await $apiFetch(
    `/api/policy/source-document-file/${sourceDoc.hash}`,
  )

  const downloadLink = document.createElement('a')
  downloadLink.href = window.URL.createObjectURL(blob)
  downloadLink.download = sourceDoc.source_document_name
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}

onBeforeMount(async () => {
  const token = await getAccessTokenSilently()

  reqHeaders.value = {
    Authorization: `Bearer ${token}`,
    'auth-provider': 'auth0',
  }
})
</script>

<template>
  <section v-if="policyDetails" class="space-y-2">
    <div>
      <h2 class="font-medium">Supporting Documentation</h2>
      <span class="inline-block text-sm text-neutral-700">
        The outcome was based on the following documentation.
      </span>
    </div>

    <ReferenceDocument
      v-for="(version, index) in policyDetails?.primary_ref_docs"
      :key="index"
      :document-version="version"
      :reference-doc="version.reference_document"
    >
      <template #default="{ source_document }">
        <div v-if="source_document" class="mt-4 flex gap-2">
          <UiButton
            v-if="source_document?.internal_url"
            class="w-full"
            variant="outlined"
            @click="activeSourceDoc = source_document"
          >
            Open source document
          </UiButton>
          <UiButton
            v-if="
              source_document.id &&
              (source_document.format === 'PDF' ||
                source_document.format === 'CSV')
            "
            class="w-full"
            variant="outlined"
            @click="onDownloadFile(source_document)"
          >
            Download PDF source file
          </UiButton>
        </div>
      </template>
    </ReferenceDocument>
    <UiTransitionFade>
      <div v-if="areDocsViewable" class="space-y-2">
        <ReferenceDocument
          v-for="(version, index) in policyDetails?.supporting_ref_docs"
          :key="index"
          :document-version="version"
          :reference-doc="version.reference_document"
        >
          <template #default="{ source_document }">
            <div v-if="source_document" class="mt-4 flex gap-2">
              <UiButton
                v-if="source_document?.internal_url"
                class="w-full"
                variant="outlined"
                @click="activeSourceDoc = source_document"
              >
                Open source document
              </UiButton>
              <UiButton
                v-if="
                  source_document.id &&
                  (source_document.format === 'PDF' ||
                    source_document.format === 'CSV')
                "
                class="w-full"
                variant="outlined"
                @click="onDownloadFile(source_document)"
              >
                Download PDF source file
              </UiButton>
            </div>
          </template>
        </ReferenceDocument>
      </div>
    </UiTransitionFade>
    <div
      v-if="policyDetails?.supporting_ref_docs.length"
      class="rounded-lg bg-neutral-50 p-4 text-sm"
    >
      <template v-if="!areDocsViewable">
        <span>
          {{ policyDetails.supporting_ref_docs.length }} more supporting
          Documents
        </span>
        <UiButton variant="text" @click="areDocsViewable = true">
          <div class="flex items-center">
            View additional supporting documents
            <div
              class="i-ph-caret-down-bold ml-1 h-4 w-4 transform-gpu rounded-full transition-transform"
            />
          </div>
        </UiButton>
      </template>
      <template v-else>
        <span>Showing all supporting Documents</span>
        <UiButton variant="text" @click="areDocsViewable = false">
          <div class="flex items-center">
            Hide additional supporting documents
            <div
              class="i-ph-caret-up-bold ml-1 h-4 w-4 transform-gpu rounded-full transition-transform"
            />
          </div>
        </UiButton>
      </template>
    </div>
    <UiDialog
      v-if="activeSourceDoc"
      :fullscreen="activeSourceDocFullscreen"
      :model-value="true"
      @hide="activeSourceDoc = null"
    >
      <div
        class="flex h-full min-h-[600px] flex-col"
        :class="{
          'max-h-screen': activeSourceDocFullscreen,
          'max-h-[600px]': !activeSourceDocFullscreen,
        }"
      >
        <header
          class="bg-surface-bg flex h-8 w-full justify-end space-x-2 px-2 py-1"
        >
          <button
            class="btn btn-icon"
            @click="activeSourceDocFullscreen = !activeSourceDocFullscreen"
          >
            <div
              v-if="activeSourceDocFullscreen"
              class="i-ph-corners-in-bold"
            />
            <div v-else class="i-ph-frame-corners-bold" />
          </button>
          <button
            class="btn btn-icon"
            aria-label="Close"
            @click="activeSourceDoc = null"
          >
            <div class="i-ph-x-bold" />
          </button>
        </header>
        <LazyUiSourceDocumentViewer
          v-if="activeSourceDoc"
          :name="activeSourceDoc.source_document_name"
          class="h-full min-h-[600px]"
          :class="{ 'h-screen': activeSourceDocFullscreen }"
          :format="activeSourceDoc.format"
          :hash="activeSourceDoc.hash"
          :req-headers="reqHeaders"
        />
      </div>
    </UiDialog>
  </section>
  <UiSkeletonDetails v-else-if="pending" class="w-full pl-0 pr-0" :count="1" />
</template>
