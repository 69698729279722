<script setup lang="ts">
import type { Api } from '@rialtic/api'

defineProps<{
  claimLine: Api.ProcessedClaimWithInsights['claimLines'][number]
}>()

const claimLineDetailsView = ref(false)
</script>

<template>
  <section class="flex flex-col space-y-2">
    <div class="flex items-center space-x-2">
      <h3 class="h3 text-neutral-900">Claim Line {{ claimLine.lineNumber }} Details</h3>
      <button
        class="bg-surface btn-text flex items-center"
        :aria-expanded="claimLineDetailsView"
        type="button"
        @click="claimLineDetailsView = !claimLineDetailsView"
      >
        View
        <span v-if="claimLineDetailsView" class="pl-1">less</span>
        <div
          class="i-ph-caret-down-bold ml-2 h-4 w-4 transform-gpu rounded-full transition-transform"
          :class="{
            'rotate-180': claimLineDetailsView,
            'rotate-0': !claimLineDetailsView,
          }"
        />
      </button>
    </div>
    <div v-if="claimLineDetailsView" class="flex flex-col space-y-2">
      <UiContentBlock>
        <div class="grid grid-cols-3 gap-4">
          <UiClaimInfo title="Date(s) of service">
            {{ formatDisplayDate(claimLine.servicedPeriod?.start) }}
            -
            {{ formatDisplayDate(claimLine.servicedPeriod?.end) }}
          </UiClaimInfo>
          <UiClaimInfo title="Days or units">
            {{ claimLine.quantity?.value }}
            {{ claimLine.quantity?.unit }}
          </UiClaimInfo>
          <UiClaimInfo title="Charges">
            ${{ claimLine.net?.value?.toFixed(2) || '-' }}
          </UiClaimInfo>
          <UiClaimInfo title="CPT®/HCPCS">
            <UiRefDataCode
              v-if="claimLine.productOrService?.coding?.[0].code"
              :code="claimLine.productOrService?.coding?.[0].code"
              model="Procedure"
            />
            <span v-else>-</span>
          </UiClaimInfo>
          <UiClaimInfo title="Diagnosis 1">
            <UiRefDataCode
              v-if="claimLine?.diagnosis1?.codeableConcept"
              :code="claimLine?.diagnosis1?.codeableConcept"
              model="Diagnosis"
            />
            <span v-else>-</span>
          </UiClaimInfo>
          <UiClaimInfo title="Additional diagnoses">
            <div v-if="claimLine.diagnosisAdditional?.length">
              <template
                v-for="(
                  { codeableConcept }, index
                ) in claimLine.diagnosisAdditional"
              >
                <UiRefDataCode :code="codeableConcept" model="Diagnosis" />
                <span
                  v-if="index !== claimLine.diagnosisAdditional.length - 1"
                  class="mr-1"
                >
                  ,
                </span>
              </template>
            </div>
            <span v-else>-</span>
          </UiClaimInfo>
          <UiClaimInfo title="Rendering provider ID">
            <div v-if="claimLine.billingName" class="capitalize">
              {{ claimLine.billingName }}
            </div>
            <span v-if="claimLine.renderingProvider?.identifiers?.npi">
              NPI:
              {{ claimLine.renderingProvider.identifiers.npi }}
            </span>
            <span
              v-else-if="claimLine.renderingProvider?.identifiers?.ein"
              class="whitespace-nowrap"
            >
              TIN:
              {{ claimLine.renderingProvider.identifiers.ein }}
            </span>
            <span v-else>-</span>
          </UiClaimInfo>
          <UiClaimInfo title="Place of service">
            <UiRefDataCode
              v-if="claimLine.locationCodeableConcept?.coding?.[0]?.code"
              :code="claimLine.locationCodeableConcept?.coding?.[0]?.code"
              model="PlaceOfService"
            />
            <span v-else>-</span>
          </UiClaimInfo>
          <UiClaimInfo title="Modifier">
            <div v-if="claimLine.modifierCodes?.length">
              <template v-for="(code, index) in claimLine.modifierCodes">
                <UiRefDataCode :code="code" model="modifier" />
                <span
                  v-if="index !== claimLine.modifierCodes.length - 1"
                  class="mr-1"
                >
                  ,
                </span>
              </template>
            </div>
            <span v-else>-</span>
          </UiClaimInfo>
          <UiClaimInfo title="Provider taxonomy">
            {{ claimLine.providerTaxonomyCodes || 'None provided' }}
          </UiClaimInfo>
        </div>
      </UiContentBlock>
      <UiContentBlock
        v-if="
          claimLine.additionalDetails?.additionalIds?.contract ||
          claimLine.additionalDetails?.parIndicator
        "
        title="Additional Line Details"
      >
        <div class="grid grid-cols-3 gap-4">
          <UiClaimInfo
            v-if="claimLine.additionalDetails?.additionalIds?.contract"
            title="Provider contract ID"
          >
            {{ claimLine.additionalDetails.additionalIds.contract }}
          </UiClaimInfo>
          <UiClaimInfo
            v-if="claimLine.additionalDetails?.parIndicator"
            title="Par indicator"
          >
            {{ claimLine.additionalDetails.parIndicator }}
          </UiClaimInfo>
        </div>
      </UiContentBlock>
    </div>
  </section>
</template>
