<script setup lang="ts">
import type { Api } from '@rialtic/api'

type DiagnosisKey = 'diagnosis2' | 'diagnosis3' | 'diagnosis4' | 'diagnosis5'
type ModifierKey = 'modifier1' | 'modifier2' | 'modifier3' | 'modifier4'

const props = defineProps<{
  connectorId: string
  claim: Api.ProcessedClaimWithInsights
  lineNumber: string
  insightId: string
}>()

const sortState = ref({
  column: 'startDate' as sortKey,
  sort: 'down' as sortValue,
})

const { data: influencing } = useApiFetch(
  () =>
    `/api/connector/${props.connectorId}/insight/${props.insightId}/influencing-claims`,
  {
    lazy: true,
    server: false,
  },
)

const formattedInfluencing = computed(() => {
  if (!influencing.value || !Array.isArray(influencing.value)) {
    return []
  }

  return influencing.value.map((item) => {
    const modifierKeys: ModifierKey[] = [
      'modifier1',
      'modifier2',
      'modifier3',
      'modifier4',
    ]
    const modifierItems = modifierKeys.reduce(
      (state, key, index) => {
        state[key] = item?.modifier?.[index]?.coding?.[0]?.code || null
        return state
      },
      {} as Record<ModifierKey, string | null>,
    )

    const diagnosisKeys: DiagnosisKey[] = [
      'diagnosis2',
      'diagnosis3',
      'diagnosis4',
      'diagnosis5',
    ]
    const diagnosisItems = diagnosisKeys.reduce(
      (state, key, index) => {
        state[key] = item?.diagnosisAdditional?.[index]?.codeableConcept || null
        return state
      },
      {} as Record<DiagnosisKey, string | null>,
    )

    return {
      ...item,
      ...modifierItems,
      ...diagnosisItems,
      id: `${item.claim?.id}:${item.lineNumber}`,
      billingProviderId: item.claim.billingProviderIdentifiers?.ein,
      claimId: item?.claim.id,
      diagnosis1: item.diagnosis1.codeableConcept,
      startDate: item?.servicedPeriod.start,
      endDate: item?.servicedPeriod.end,
      netValue: item?.net?.value,
      patientBirthdate: item.claim.patient?.birthDate,
      patientGender: item.claim.patient?.gender,
      placeOfService: item?.locationCodeableConcept?.coding?.[0]?.code,
      preAuthRef: item.claim.insurance?.[0].preAuthRef?.[0],
      procedureCode: item?.productOrService?.coding?.[0]?.code,
      quantityValue: item?.quantity?.value,
      renderingProviderId:
        item.renderingProviderNpi || item.renderingProviderTin,
      totalValue: item.claim.total?.value,
      secondaryHighlight: item.claim.id === props.claim?.id,
    }
  })
})
</script>

<template>
  <section class="space-y-2">
    <h2 class="font-medium">Influencing Claim Lines</h2>
    <div
      v-if="formattedInfluencing.length"
      class="border-1 max-h-87 overflow-auto rounded-lg border-neutral-300"
    >
      <UiInfluencingClaimLines
        :claim="claim!"
        :hide-end-message="true"
        :influencing-claims="formattedInfluencing"
        :line-number="lineNumber"
        :sort-options="sortState"
        :sticky="false"
        @sort:state=""
      />
    </div>
    <span v-else class="inline-block text-sm text-neutral-700">
      The outcome was not influenced by other claim lines.
    </span>
  </section>
</template>
