<script setup lang="ts">
import type { Api } from '@rialtic/api'

const props = defineProps<{
  claim: Api.ProcessedClaimWithInsights
}>()

const route = useRoute<'connectorId-claimId-claimLine'>()

useHead(() => ({
  title: `${route.params.claimId} / ${route.params.claimLine}`,
}))

const lineNumber = computed(() => route.params.claimLine)
const claimLine = computed(() =>
  props.claim.claimLines.find(
    (line) => `${line.lineNumber}` === `${lineNumber.value}`,
  ),
)
const recommendedInsight = computed(() =>
  getRecommendedInsight(claimLine.value?.insights),
)
const insightBadgeLabel = getInsightBadgeLabel(recommendedInsight.value)
const insightDecision = getInsightDecision(recommendedInsight.value)

const start = computed(() => claimLine.value?.servicedPeriod?.start)

const additionalIds = computed(
  () => props.claim?.additionalDetails?.additionalIds || null,
)
const billingName = computed(() =>
  props.claim?.provider?.practitioner?.name?.family?.toLowerCase(),
)

const claimHeaderView = ref(false)

watch(
  recommendedInsight,
  (newVal, _oldVal) => {
    if (newVal) {
      $apiFetch('/api/analytics/log-insight-view', {
        method: 'POST',
        body: {
          connector_id: route.params.connectorId,
          policy_id: recommendedInsight.value?.policyId,
          policy_name: recommendedInsight.value?.policyName,
          concept_id: recommendedInsight.value?.insight.insight.conceptId,
          edit_type: recommendedInsight.value?.editType?.edit_type,
          claim_line_date_of_service_start:
            claimLine.value?.servicedPeriod.start,
          procedure_code: claimLine.value?.productOrService?.coding[0]?.code,
          carc: recommendedInsight.value?.insight.insight.reasonCodeLevel1,
          rarc: recommendedInsight.value?.insight.insight.reasonCodeLevel2,
          insight_id: recommendedInsight.value?.insight.insight.id,
        },
      })
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <main class="flex flex-col space-y-6 p-4 lg:pt-6">
    <div class="flex items-center space-x-2">
      <h1 class="h1 text-neutral-900">Claim Line {{ lineNumber }}</h1>
      <div
        v-if="insightBadgeLabel"
        class="subtitle-2 inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-yellow-800"
      >
        {{ insightBadgeLabel }}
      </div>
    </div>
    <section v-if="insightDecision">
      <h2 class="h3 mb-2 text-neutral-900">Decision</h2>
      <UiContentBlock>
        <div class="mb-5">
          <h4 class="mb-3 text-xs text-neutral-700">Claim Line Status</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ insightDecision.status }}
          </p>
        </div>
        <div v-if="recommendedInsight && recommendedInsight.editType" class="mb-5">
          <h4 class="mb-3 text-xs text-neutral-700">Logic Type</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ recommendedInsight.editType.edit_type }}
          </p>
        </div>
        <div>
          <h4 class="mb-3 mt-4 text-xs text-neutral-700">Reason</h4>
          <p class="body-1 break-words text-neutral-900">
            {{ insightDecision.reason }}
          </p>
        </div>
      </UiContentBlock>
    </section>

    <SectionInfluencingClaims
      v-if="recommendedInsight"
      :claim="claim"
      :connector-id="route.params.connectorId"
      :insight-id="recommendedInsight?.insight.insight.id"
      :line-number="lineNumber"
    />

    <SectionReferenceDocuments
      v-if="recommendedInsight"
      :policy-id="recommendedInsight.insight?.insight?.policyId"
      :start-date="start"
    />

    <ClaimLineDetails v-if="claimLine" :claim-line="claimLine" />

    <section class="flex flex-col space-y-2">
      <div class="flex items-center space-x-2">
        <h2 class="h3 text-neutral-900">Claim Header</h2>
        <button
          class="bg-surface btn-text flex items-center"
          :aria-expanded="claimHeaderView"
          type="button"
          @click="claimHeaderView = !claimHeaderView"
        >
          View
          <span v-if="claimHeaderView" class="pl-1">less</span>
          <div
            class="i-ph-caret-down-bold ml-2 h-4 w-4 transform-gpu rounded-full transition-transform"
            :class="{
              'rotate-180': claimHeaderView,
              'rotate-0': !claimHeaderView,
            }"
          />
        </button>
      </div>
      <div v-if="claimHeaderView" class="flex flex-col space-y-2">
        <UiContentBlock title="Claim Header Details">
          <div class="grid grid-cols-3 gap-4">
            <UiClaimInfo title="Patient name">
              <Protected>
                {{ claim.patientName }}
              </Protected>
            </UiClaimInfo>

            <UiClaimInfo title="Member ID">
              <Protected :disabled="!claim.subscriberId">
                {{ claim.subscriberId || '-' }}
              </Protected>
            </UiClaimInfo>

            <UiClaimInfo title="Member group ID">
              <Protected :disabled="!claim.memberGroupId">
                {{ claim.memberGroupId || '-' }}
              </Protected>
            </UiClaimInfo>

            <UiClaimInfo title="Patient birthdate">
              <Protected>
                {{ formatDisplayDate(claim.patient?.birthDate) || '-' }}
              </Protected>
            </UiClaimInfo>

            <UiClaimInfo title="Patient sex">
              <Protected class="capitalize">
                {{ claim.patient?.gender || '-' }}
              </Protected>
            </UiClaimInfo>

            <UiClaimInfo title="Total charged">
              ${{ claim.total?.value.toFixed(2) || '-' }}
            </UiClaimInfo>

            <UiClaimInfo title="Date(s) of service">
              {{ formatDisplayDate(claim.servicedPeriod?.start) }} -
              {{ formatDisplayDate(claim.servicedPeriod?.end) }}
            </UiClaimInfo>

            <UiClaimInfo title="Billing provider ID">
              <p v-if="billingName" class="capitalize">
                {{ billingName }}
              </p>
              <p class="whitespace-nowrap">
                TIN: {{ claim.billingProviderIdentifiers?.ein || '-' }}
              </p>
              <p>NPI: {{ claim.billingProviderIdentifiers?.npi || '-' }}</p>
            </UiClaimInfo>

            <UiClaimInfo title="Prior auth #">
              {{ claim.insurance?.[0].preAuthRef?.[0] || '-' }}
            </UiClaimInfo>
          </div>
        </UiContentBlock>
        <UiContentBlock title="Diagnosis Codes">
          <div class="grid grid-cols-6 gap-x-4 gap-y-2">
            <UiRefDataCode
              v-for="code in claim.diagnosisCodes"
              :code="code.codeableConcept"
              model="Diagnosis"
            />
          </div>
        </UiContentBlock>
        <UiContentBlock
          v-if="claim.additionalDetails"
          title="Additional Details"
        >
          <div class="grid grid-cols-3 gap-4">
            <UiClaimInfo
              v-if="claim.additionalDetails.specialtyCodes"
              title="Provider & Rialtic specialty codes"
            >
              <div class="flex flex-wrap gap-1">
                <div
                  v-for="codeSet in claim.additionalDetails.specialtyCodes"
                  class="body-2 min-w-[46px] rounded-md bg-neutral-100 p-1 px-2"
                >
                  <p class="text-primary-900">
                    {{ codeSet.raw }}
                  </p>
                  <UiTooltip v-if="codeSet.description">
                    <template #activator>
                      <slot>
                        <div
                          class="body-2 text-secondary-700 hover:text-secondary-800 active:text-secondary-900 inline-block"
                        >
                          {{ codeSet.value }}
                        </div>
                      </slot>
                    </template>
                    <div class="p-2">
                      <p class="body-2 max-w-prose">
                        <span class="subtitle-2">
                          {{ codeSet.value }}
                        </span>
                        - {{ codeSet.description }}
                      </p>
                    </div>
                  </UiTooltip>
                  <p v-else class="text-secondary-700">
                    {{ codeSet.value }}
                  </p>
                </div>
              </div>
            </UiClaimInfo>
            <UiClaimInfo
              v-if="additionalIds"
              title="Billing provider additional IDs"
            >
              <p v-if="additionalIds.altId">
                Alt ID: {{ additionalIds.altId }}
              </p>
              <p v-if="additionalIds.network">
                Network: {{ additionalIds.network }}
              </p>
              <p v-if="additionalIds.contract">
                Contract: {{ additionalIds.contract }}
              </p>
            </UiClaimInfo>
            <UiClaimInfo
              v-if="claim.additionalDetails.ltssIndicator"
              title="LTSS indicator"
            >
              {{ claim.additionalDetails.ltssIndicator }}
            </UiClaimInfo>
            <UiClaimInfo
              v-if="claim.additionalDetails.parIndicator"
              title="Par indicator"
            >
              {{ claim.additionalDetails.parIndicator }}
            </UiClaimInfo>
          </div>
        </UiContentBlock>
      </div>
    </section>
  </main>
</template>
